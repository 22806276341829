
import { defineComponent } from "vue"
import CurrencyValueMask from '@/services/CurrencyValueMask' 

export default defineComponent({
    name: "InputMoney",
    setup(props){
        return{CurrencyValueMask}
    }
})
