
import { ref, Ref, watchEffect } from 'vue';

export default {
  name: 'Folder',
  props: {
    folderName: String,
  },
  setup(props) {

    const timer = ref();
    const isOut = ref(true);
    const isOpen: Ref<boolean> = ref(true);
    
    function closeModalClickOut() {
      isOut.value = !isOut.value
        timer.value = setTimeout(() => {
          isOut.value = !isOut.value
        }, 205)
      
      const clearTimeoutFn = () => {
        clearTimeout(timer.value);
      };

      clearTimeoutFn()
    }

    const toggleOpen = () => {
      closeModalClickOut()
        setTimeout(() => {
          isOpen.value = !isOpen.value
          
        }, 205)
    };

    return {
      isOpen,
      toggleOpen,
      isOut
    };
  },
};
