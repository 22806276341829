import { defineStore } from "pinia";
export const useChangeContainer = defineStore("ChangeContainer", {
  state: () => {
    return {
      fluid: false
    }
  },
  actions: {
    open(): void {
      this.fluid = true
    },
    close(): void {
      this.fluid = false
    }
  },
  getters: {
    status(): boolean {
      return this.fluid
    }
  }
});






