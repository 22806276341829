<template>
<div class="d-flex flex-row justify-content-between align-items-center mb-2" style="min-height: 50px; border-bottom: 0.5px solid #e6e6e6">
    <h7 class="mb-0 fw-bold">Editando {{ princTitle }}</h7>
    <slot v-if="option1 !== null">
        <h7 style="font-size:10px; font-weight: bold" v-if="option1 === 'S'">Dinâmico</h7>
        <h7 style="font-size:10px; font-weight: bold" v-else>Fixo</h7>        
    </slot>
</div>
</template>
<script>
import { defineComponent } from "vue"

export default defineComponent({
    name: 'TitleForm',
    props: {
        princTitle: String, option1: String | null
    },
    setup() {
        return {}
    },
})
</script>