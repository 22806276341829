
import { ref, Ref, watch } from 'vue';
import { Cardapio, ItemEstoque, Pacote, Produto } from '../Cardapio.vue';
import TitleForm from './TitleForm.vue';
import Api from '@/services/Api';
import Button from "@/components/Button.vue"
import CurrencyValueMask from '@/services/CurrencyValueMask'
import useAlert from "@/composables/Alert";
import InputMoney from '@/components/InputMoney.vue';

export default {
  name: 'FormEdit',
  components: {TitleForm, Button, InputMoney},
  props: { formItem: Object, delete: Function, closeForm: Function,codConcessionaria: Number, formCardapio: Object, formPacote: Object, typeForm: String, itensFixos: Array, estoque: Array, arrayGrupos: Array ,itensDinamicos: Array, iniciarComMarcacao: Number},
  setup(props, {emit}) {
    const { showTimeAlert } = useAlert();
    const dialogVisible: Ref<boolean> = ref(false);
    const temPeca: Ref<boolean> = ref(false);
    const isOpen: Ref<boolean> = ref(true);
    const tipoItem: Ref<string> = ref('')
    const count: Ref<number> = ref(0)
    const productEdit: Ref<Produto | any> = ref({            
      servico:  null,
      dadosExtra:  null,
      codItemLoja:  null,
      codigoMaoObra:  null,
      valorMaoObra:  null,
      tempoMaoObra:  null,
      iniciarComMarcacao:  null,
      precoPeca:  null,
      dinamico: null,
      codAgregados:  null,
      tituloDoForm: null,
      codConcessionaria: null,
      imagem:  null,
      video:  null,
      descricao: null,
      grupo:  null,
      itensAgrupados: [{nome: null,valorMo: null,codItemLoja: null}],
      estoque: null,
      qtd: null,
      codigoMaoObraAlacarte: null,
      codConfigPacote: null,
      codigoPeca: null,
    })
    const pacoteEdit: Ref<Pacote | any> = ref({
        acrescimoalacarte: null,
        codConcessionaria: null,
        codPacote: null,
        descricao: null,
        id: null,
        idCardapio: null,
        jurosApos: null,
        jurosDe: null,
        nome: null,
        nomeFantasia: null,
        ordem: null,
        parcelaPadrao: null,
        parcelas: null,
        tipoOS: null
    })
    const cardapioEdit: Ref<Cardapio | any> = ref({
        codConcessionaria: null,
        exibirNoTopo: null,
        id: null,
        nome: null,
        padrao: null,
        qtd: null,
        tipoCardapio: null,
        versao: null
    })
    const itensAgrupadosCopy: Ref<any> = ref([{nome: null,valorMo: null,codItemLoja: null}])
    async function updateOrCreateProduct(evt: Event){
      evt.preventDefault()
      try {
        if(Number(productEdit.value.dadosExtra) === 43){
          productEdit.value = {...productEdit.value, tipoProduto: 'produtoFixo'}
        }
        if(!productEdit.value.codAgregados){
          productEdit.value.dinamico = tipoItem.value === 'produtoFixo' ? 'N' : 'S'
        }
        const res = await Api.post('cardapio/createOrUpdateItem', {...productEdit.value, codConcessionaria: props.codConcessionaria})
        if(!res.data.error){
          emit('getCardapioById', props.codConcessionaria)
          emit('closeForm')
          showTimeAlert(productEdit.value.codAgregados ? 'Produto atualizado' : 'Produto adicionado', 'success');
          return;
        }
        showTimeAlert('Confira os campos e tente novamente', 'error');
      } catch (error) {
        console.log(error)
      }
    }

    async function updateOrCreatePacote(evt: Event){
      evt.preventDefault()
      try {
        const res = await Api.post('cardapio/createOrUpdatePacote', {...pacoteEdit.value, codConcessionaria: props.codConcessionaria})
        if(!res.data.error){
          emit('getCardapioById', props.codConcessionaria)
          emit('closeForm')
          showTimeAlert(pacoteEdit.value.id ? 'Pacote atualizado' : 'Pacote adicionado', 'success');
          return;
        }
        showTimeAlert('Confira os campos e tente novamente', 'error');
      } catch (error) {
        console.log(error)
      }
    }

    async function updateOrCreateCardapio(evt: Event){
      evt.preventDefault()
      try {
        const res = await Api.post('cardapio/createOrUpdateCardapio', {...cardapioEdit.value, codConcessionaria: props.codConcessionaria})
        if(!res.data.error){
          emit('getCardapioById', props.codConcessionaria)
          emit('closeForm')
          showTimeAlert(cardapioEdit.value.id ? 'Cardápio atualizado' : 'Cardápio adicionado', 'success');
          return;
        }
        showTimeAlert('Confira os campos e tente novamente', 'error');
      } catch (error) {
        console.log(error)
      }
    }

    function getPropNoEstoque(codigo: string){
      const finded: ItemEstoque | any = props.estoque && props.estoque.find((item: any) => item.codProdutoLoja === codigo)
      
      if (finded) {
        const {preco, estoque, produto} = finded
        productEdit.value.precoPeca = preco
        productEdit.value.servico = produto
      }
    }

    const setNomeCardapio = (id: number) => {
      if(productEdit.value.codAgregados){return;}

      if(tipoItem.value === 'produtoFixo'){
        const finded: any = props.itensFixos && props.itensFixos.find((item: any) => Number(item.codPersonalizacao) === id)
        productEdit.value.servico = finded.nomePersonalizacao
      }
      if(tipoItem.value === 'produtoDinamico'){
        const finded: any = props.itensDinamicos && props.itensDinamicos.find((item: any) => Number(item.codPersonalizacao) === id)
        productEdit.value.servico = finded.nomePersonalizacao
      }    
    }

    const addItensAgrupados = () => {
      productEdit.value.itensAgrupados = [...productEdit.value.itensAgrupados,{nome: null,valorMo: null,codItemLoja: null}];
      count.value++
    };

    watch(() => props.formItem, (newFormItem) => {
      if(props.formItem && props.formItem.hasOwnProperty('codAgregados')){
        
        productEdit.value = {...productEdit.value, itensAgrupados: [{nome: null,valorMo: null,codItemLoja: null}]};
        productEdit.value = {...productEdit.value , ...newFormItem};
      }
      //verificar - nao permitir q array seja zerado. 
      // productEdit.value.itensAgrupados = [{nome: null,valorMo: null,codItemLoja: null}]
    }, { deep: true, immediate: true});

    watch(() => props.formCardapio, (newFormItem) => {
      if(props.formCardapio && props.formCardapio.hasOwnProperty('tipoCardapio')){
        cardapioEdit.value = newFormItem;
      }
    }, { deep: true, immediate: true});
    
    watch(() => props.formPacote, (newFormItem) => {
      if(props.formPacote && props.formPacote.hasOwnProperty('codPacote')){
        pacoteEdit.value = newFormItem;
      }
    }, { deep: true, immediate: true});

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    return { isOpen, toggleOpen, setNomeCardapio, updateOrCreateProduct,updateOrCreatePacote, temPeca, dialogVisible, updateOrCreateCardapio, productEdit, pacoteEdit, cardapioEdit, tipoItem, getPropNoEstoque, count, addItensAgrupados, CurrencyValueMask, itensAgrupadosCopy};
  },
};
